import { useEffect } from 'react';
import { useLocation, useMatches } from '@remix-run/react';

import * as Sentry from "@sentry/remix";

import configuration from '~/configuration';
import isBrowser from '~/core/generic/is-browser';

let initialized = false;

/**
 * @description Loads and initializes Sentry for tracking runtime errors
 */
function initializeBrowserSentry() {
  const dsn = configuration.sentry.dsn;

  if (!isBrowser() || initialized) {
    return;
  }

  if (!dsn) {
    if (configuration.production) {
      warnSentryNotConfigured();
    }

    return;
  }

  Sentry.init({
    dsn,
    release: configuration.release,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
    ]
  });

  initialized = true;
}

function warnSentryNotConfigured() {
  console.warn(
    `Sentry DSN was not provided. Please add a SENTRY_DSN environment variable to enable error tracking.`,
  );
}

export default initializeBrowserSentry;
